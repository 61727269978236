@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply snap-y snap-proximity scroll-smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  @apply bg-white text-gray-900;
}

/* Ensure our body and wrapper have full height */
html,
body,
#__next {
  @apply h-full;
}

#__next {
  @apply flex flex-col;
}

@layer base {
}

@layer components {
  /* TODO: Sidebar for individual asset pages, might be able to delete with redesign */
  @media (min-width: 768px) {
    .right-w-drawer {
      right: 24rem;
    }
  }

  @media (min-width: 1024px) {
    .right-w-drawer {
      right: 36rem;
    }
  }

  /* TODO: Blog styles, can probably delete */
  .post-content a {
    @apply underline text-blue-600 hover:text-blue-400;
  }

  /* Terms and condition styles */
  .terms-and-privacy h2 {
    @apply text-lg font-bold leading-6 mt-8 mb-2;
  }

  .terms-and-privacy h3 {
    @apply text-base font-bold leading-6 mt-8 mb-2;
  }

  .terms-and-privacy p,
  .terms-and-privacy-explanation p {
    @apply text-base leading-6 my-8;
  }

  .terms-and-privacy h2 + p {
    @apply text-base leading-6 mt-2;
  }

  .terms-and-privacy h3 + p {
    @apply text-base leading-6 mt-2;
  }

  .terms-and-privacy ul {
    @apply text-base leading-6 my-8 pl-4 list-disc;
  }

  .terms-and-privacy ol {
    @apply text-base leading-6 my-8 pl-4 list-decimal;
  }

  .terms-and-privacy ol ol {
    @apply text-base leading-6 my-2 pl-4;
  }

  .terms-and-privacy ul ul {
    @apply text-base leading-6 my-2 pl-4 list-disc;
  }

  .terms-and-privacy li {
    @apply text-base leading-6 my-2;
  }

  .terms-and-privacy a {
    @apply underline;
  }

  /* TODO: Delete this, it's for the old landing page */
  .explore-button-bg {
    background: linear-gradient(
      135deg,
      rgba(80, 152, 248, 1) 7%,
      rgba(57, 117, 255, 1) 100%
    );
  }
}

@layer utilities {
  /* NOTE: This handles the case where the original text is regular weight,
           but we want to make it bold on hover and don't want the container
           to change width.
           */
  .space-for-bold::before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  .split-bg {
    background: linear-gradient(90deg, #ffffff 50%, #194185 50%);
  }

  /* TODO: Move these into tailwind config */
  .animate-quarter-spin {
    animation: quarter-spin 1s ease-in-out infinite;
  }

  @keyframes quarter-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(90deg);
    }
  }

  /* TODO: Move these into tailwind config */
  .desktop-aspect {
    padding-bottom: 56.25%;
  }

  .phone-aspect {
    padding-bottom: 177.78%;
  }

  /* TODO: Use the tailwind class instead of this one */
  a.disabled {
    pointer-events: none;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .animate-fade-in {
    @apply opacity-0;
    animation: fade-in 5s ease 1s forwards;
  }

  .animate-fade-in-fast {
    @apply opacity-0;
    animation: fade-in 2s ease 0.2s forwards;
  }

  .animate-fade-out {
    animation: fade-out 5s ease 1s forwards;
  }

  .animate-delay-2 {
    animation-delay: 2s;
  }

  .loading-ellipsis {
    font-size: 30px;
  }

  .loading-ellipsis:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '\2026'; /* ascii code for the ellipsis character */
    width: 0px;
  }

  @keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
}
